const state = {
    DataHoraZulu: ''
}

const getters = {
    horaZuluFormatada: state => state.DataHoraZulu.substring(11,19),
    dataZuluFormatada: state => state.DataHoraZulu.substring(0, 13).replace(/-/g,'').replace(/T/g,'')
}

const mutations = {
    modificarDataHoraZulu: (state, payload) => {
        state.DataHoraZulu = payload
    }
}

const actions = {
    modificarDataHoraZulu: (context, payload) => {
        context.commit('modificarDataHoraZulu', payload)
    }
}

export default{
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}