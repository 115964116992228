<template>
    <div>
        <h3 id="hora" class="fw-bolder">{{horaZuluFormatada}}</h3>
    </div>
</template>

<script>

import axios from 'axios'
import apiUrl from './../APIs/apiUrl'

import {createNamespacedHelpers} from 'vuex'

const {mapActions,mapGetters} = createNamespacedHelpers('DataHoraZulu')

export default {
    /*data() {
        return {
            horaApi: undefined,
        }
    },
    watch: {
        horaApi(){
            this.horaApi
        }
    },*/
    computed: {
        ...mapGetters (['horaZuluFormatada'])
    },
    created () {
        setInterval(() => {

            axios.get(apiUrl.apiUrlHoraZulu.apiUrl)
                    .then((response) => {
                        this.modificarDataHoraZulu(response.data.utc_datetime)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
        }, 1000);
    },
    methods: {
        ...mapActions (['modificarDataHoraZulu'])
    }
}
</script>

<style scoped>
    #hora {
        font-size: 60px;
        color: #ffffff
    }
    @media only screen and (max-width: 715px) {
    #hora {
    font-size: 20px;
  }
}
</style>