<template>
  <v-app>
    <v-app-bar
      app
      color="#005288"
      dark
    >
      <div class="d-flex align-center">
        <h1><i class="fas fa-bell me-2"></i>METCOR</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://time.is/Z"
        target="_blank"
        text
      >
        <hora-zulu/>
      </v-btn>
    </v-app-bar>

    <v-main  id="trazerCursor">

<template>

<a style="text-decoration: none" data-bs-toggle="modal" class="me-2" data-bs-target="#sugestao">
    <v-btn
        color="light-green darken-4"
        dark 
        fixed 
        small
        bottom 
        left 
        fab
        >
        <v-icon>mdi-headset</v-icon>
    </v-btn>
    </a>
    <v-btn v-if="irParaTopo >= 200"
        color="orange"
        dark 
        fixed 
        bottom 
        small
        right 
        fab
        @click="$vuetify.goTo(0,0)">
        <v-icon>mdi-arrow-up</v-icon>
    </v-btn>

</template>

      <div class="container mt-5">
      <v-alert 
        color="orange"
        dismissible
        type="warning">
        O sistema está em fase de testes. Dúvidas ou sugestões envie um email, clicando no botão verde no canto inferior esquerdo da tela <v-icon>mdi-headset</v-icon>
      </v-alert>
      
          <router-link to="/" class="me-2" style="text-decoration:none">
            <v-btn
              color="warning"
              fab
              small
              dark
              title="Página de Alerta"
            >
              <v-icon>mdi-alarm</v-icon>
            </v-btn>
          </router-link>

          <router-link to="/erro-metar" class="me-2" style="text-decoration:none">
            <v-btn
              color="primary"
              fab
              small
              dark
              title="Página de coferência"
            >
              <v-icon>mdi-comment-search-outline</v-icon>
            </v-btn>
          </router-link>
        
    
      <a style="text-decoration: none" data-bs-toggle="modal" data-bs-target="#ajuda">
        <v-btn
              color="error"
              fab
              small
              dark
              title="Página de ajuda"
            >
              <v-icon>mdi-help</v-icon>
            </v-btn>
      </a>
      </div>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
  </v-main>

    <v-footer
    color="#005288"
    padless
    position="fixed">

        <v-row
          justify="center"
          no-gutters>

          <v-btn
            v-for="link in links"
            :key="link.id"
            color="white"
            text
            rounded>
            <a :href="link.end" target="=_blank" class="text-light text-decoration-none">{{ link.titulo }}</a>
          </v-btn>

          <v-col
            class="primary lighten-2 py-4 text-center white--text"
            cols="12">
            <a class="text-light text-decoration-none" href="https://www.instagram.com/sydneyfernandesaraujo/" target="_blank">{{ new Date().getFullYear() }} — <v-icon class="text-light">mdi-instagram</v-icon> <strong>Sydney Fernandes</strong></a>
          </v-col>
        </v-row>
    </v-footer>
    <modalEmail></modalEmail>

<modal
        :titulo="'Ajuda'"
        identificacao="ajuda"
        cor="danger"
        >
        <h6 style="text-decoration: none; color: grey; font-size: 15pt">O sistema foi desenvolvido para auxiliar nas atividades dos órgãos de meteorologia aeronáutica.</h6>
        <table class="table table-sm table-bordered">
          <tbody>
            <tr>
              <th>Página Alerta:</th>
            </tr>
            <tr>
              <td>O sistema informará através de alarme se a mensagem não estiver na rede quando der a hora cheia.</td>
            </tr>
            <tr>
              <td>O sistema está utilizando a API da REDEMET, caso haja alguma instabilidade na REDEMET o METCOR será impactado.</td>
            </tr>
            <tr>
              <td>Para maior confiabilidade no aviso de mensagem ausente, sujere-se que o METCOR fique em primerio plano (Visível na tela do computador do operador). </td>
            </tr>

            <tr>
              <th>Página Conferência:</th>
            </tr>
            <tr>
              <td>O sistema informará um possível erro na confecção do METAR conforme publicções ICA 105-15 e ICA 105-16.</td>
            </tr>
            <tr>
              <td>Por questão de interpretação, pode acontecer algumas divergências na correção do METAR. Entre em contado para que possamos aperfeiçoar o sistema.</td>
            </tr>
            <tr>
              <td>Para facilitar na buscar por mais de uma localidade, o sistema conta com a opção de criar grupo (semelhante ao da REDEMET), é só ir na opção de criar grupo e preencher o formulário. </td>
            </tr>
            <tr>
              <td>As consultas são limitadas a um intervalo de 24h. </td>
            </tr>

            <tr>
              <th>Dúvidas e/ou sugestões:</th>
            </tr>
            <tr>
              <td>Clique no botão verde no canto inferior esquerdo da tela <v-icon>mdi-headset</v-icon></td>
            </tr>
            <tr>
              <td>As mensagens serão respondidas sempre que possível.</td>
            </tr>
          </tbody>
        </table>
        </modal>
  </v-app>
</template>

<script>

import HoraZulu from './components/HoraZulu.vue'
import modal from './components/modal.vue'
import modalEmail from './components/modalEmail.vue'

export default {
  name: 'App',
  components: {
    HoraZulu,
    modal,
    modalEmail
  },
  data: () => ({
      links: [
        {id: 1, titulo: 'REDEMET', end: 'https://redemet.decea.mil.br/'},
        {id: 2, titulo: 'Publicações DECEA', end: 'https://publicacoes.decea.mil.br/'},
      ],
      irParaTopo: 0,
    }),

    mounted() {

            window.addEventListener("scroll", () => {
                var scrollAtual = document.documentElement.scrollTop;
                this.irParaTopo = scrollAtual;
            })}
};
</script>


