//esse constate vai passar a hora zulu para o arquivo horaZulu
const apiUrlHoraZulu = {
        apiUrl: 'https://worldtimeapi.org/api/timezone/Europe/London',
     
}
//esse constate vai passar a data zulu para o arquivo ErroMetar
const apiMetarConferencia =  {
    apiUrlmetar: (local, dataIni,horaIni,dataFim,horaFim) => {
       return 'https://redemet.decea.gov.br//api/consulta_automatica/index.php?local='+ local +'&msg=metar&data_ini='+dataIni+horaIni+'&data_fim='+dataFim+horaFim
    }
}
//esse constate vai passar a hora zulu para o arquivo metarAlerta
const apiMetarAlerta = {
    apiUrlMetarAlerta: (local, data) => {
        return 'https://redemet.decea.gov.br//api/consulta_automatica/index.php?local='+ local +'&msg=metar&data_ini='+data+'&data_fim='+data
    }
}

const grupoMetarLogin = {
    apiUrlLogin: (nome, email, password) => {
        return 'https://grupo-localidades.herokuapp.com/api/login/cadastro?name='+ nome + '&email='+ email +'&password='+password
    }
} 

const grupoMetarLoginPuxar = {
    apiUrlLogin: (email, password) => {
        return 'https://grupo-localidades.herokuapp.com/api/login/?email='+ email +'&password='+password
    }
}

const grupoMetar = {
    apiUrlGrupoMetar:  'https://grupo-localidades.herokuapp.com/api/v1/grupoMetar'
}

export default {
    apiUrlHoraZulu,
    apiMetarConferencia,
    apiMetarAlerta,
    grupoMetarLogin,
    grupoMetarLoginPuxar,
    grupoMetar
}
