<template>
<div>
<v-container>
        <div class="alert alert-warning alert-dismissible fade show" v-if="mensagemErro">
            <strong>{{mensagemErro}}</strong>
            <button type="button" class="btn-close" @click="fecharAlerta"></button>
        </div>

        <!--<v-banner
            single-line
            transition="slide-y-transition"
            class="mb-10 float-end"
        >
      
            <v-text-field
            label="Localidade"
            hide-details="auto"
            v-model="localSolicitacao"
            ></v-text-field>
            <template v-slot:actions="">
            
                <v-btn
                    color="primary"
                    text
                    @click="solicitarLocalidade"
                >
                    Confirmar
                </v-btn>
            </template>
        </v-banner>-->

     <template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title class="bg-primary"
        >
        <v-icon dark class="text-danger" x-large="">mdi-bell</v-icon>

                      
                <v-btn text class="danger">
                    <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#toque">Toques <v-icon dark ="">mdi-music</v-icon></a>
                </v-btn>
                <v-btn text class="danger">
                    <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#intervalo">Intervalo<v-icon dark ="">mdi-clock</v-icon></a>
                </v-btn>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
                <v-text-field
            label="Localidade"
            append-icon="mdi-map-marker"
            append-outer-icon='mdi-send'
            hide-details="auto"
            dark
            v-model="localSolicitacao"
            @keyup.enter="solicitarLocalidade"
            @click:append-outer="solicitarLocalidade"
            ></v-text-field>
          
        </v-card-title>

        <v-simple-table v-if="this.metar">
                <v-progress-linear v-if="this.class==false"
                    buffer-value="0"
                    stream
                    color="primary"
                     height="10"
                ></v-progress-linear>
                <v-progress-linear v-else
                    indeterminate
                    color="red"
                     height="10"
                ></v-progress-linear>
       
            <tbody>
                <tr>
                <template>
                    <td v-if="busca == false"><v-icon class="text-danger mt-2">mdi-airplane-marker</v-icon><h1 class="fw-bolder p-4 text-danger text-center">Buscando...</h1> </td>
                    <td v-else-if="(metar.includes('METAR') == false && metar.includes('SPECI') == false) || metar.includes('Mensagem METAR')== true"><v-icon class="text-danger mt-2">mdi-airplane-marker</v-icon><h1 class="fw-bolder p-4 text-danger text-center">{{localidade.toUpperCase()+ " não foi encontrada"}}</h1> </td>
                    <td v-else><v-icon v-if="this.class == true" class="text-danger mt-2">mdi-airplane-alert</v-icon><v-icon v-if="this.class == false" class="text-primary mt-2">mdi-airplane-marker</v-icon><h1 :class="classMetar" class="corText text-center">{{metar}}</h1></td>
                </template>
                </tr>
            </tbody>
        </v-simple-table>
        

        <v-simple-table v-else>
            <tbody>
                <tr>
                    <td><h1 :class="classMetar" class="text-secondary text-center"><v-icon class="text-danger">mdi-map-marker</v-icon> <v-spacer></v-spacer> Informe a localidade</h1> </td>
                </tr>
            </tbody>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>


        <audio id="myAudio" v-if="audio == true && toques == 'toque1'">            
            <source src="../assets/toque1.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque2'">            
            <source src="../assets/toque2.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque3'">            
            <source src="../assets/toque3.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque4'">            
            <source src="../assets/toque4.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque5'">            
            <source src="../assets/toque5.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque6'">            
            <source src="../assets/toque6.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque7'">            
            <source src="../assets/toque7.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque8'">            
            <source src="../assets/toque8.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque9'">            
            <source src="../assets/toque9.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque10'">            
            <source src="../assets/toque10.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <audio id="myAudio" v-else-if="audio == true && toques == 'toque11'">            
            <source src="../assets/toque11.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        </v-container>

<modal
    :titulo="'Ativação do alarme'"
    identificacao="intervalo"

    cor="danger"
    >
    <table class="tabel">
        
        <tbody>
            <tr>
                <td>
                    <v-select
                        :items="horaSelect"
                        label="Início"
                        v-model="horaIni"
                    ></v-select>
                </td>
                <td>
                    <v-select
                        :items="horaSelect"
                        label="Fim"
                        v-model="horaFim"
                    ></v-select>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    Atenção!!! o sistema só detectará METAR ausente dentro do intervalo escolhido.
                </td>
            </tr>
        </tbody>
    </table>

</modal>




<!--|Modal para selecionar o toque para o alarme-->
<modal
    :titulo="'Mensagens com correção'"
    identificacao="toque"

    cor="danger"
    >
        <table class="table">
            <thead>
                <tr class="text-center bg-secondary text-light">
                <th>Tipo de toques</th>     
                <th>Selecionar</th>     
                </tr>
            </thead>                    
            <tbody>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque1.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque1" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque2.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque2" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque3.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toqu32" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque4.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque4" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque5.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque5" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque6.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque6" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque7.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque7" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque8.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque8" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque9.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque9" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque10.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque10" v-model="toques">
                    </td>
                </tr>
                <tr>
                    <td>
                        <audio controls>
                            <source src="./../assets/toque11.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                    <td class="pt-5">
                            <input type="radio" name="toque" value="toque11" v-model="toques">
                    </td>
                </tr>
            </tbody>
        </table>
        
</modal>
</div>        
</template>





<script>

import modal from './../components/modal.vue'
import axios from 'axios'
import apiUrl from './../APIs/apiUrl'
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('DataHoraZulu')

export default {
    components: {
        modal,
    },
    data () {
        return {
            horaSelect: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            localidade: undefined,
            localSolicitacao: null,
            mensagemErro: undefined,
            metar: undefined,
            busca: false,
            horaMetar: undefined,
            class: false,
            seg: undefined,
            audio: false,
            toques: 'toque1',
            horaIni: '00',
            horaFim: '23'
        }
    },
    created (){

        setInterval(() => {
            this.seg = new Date().getSeconds() < 10 ? '0'+new Date().getSeconds() : new Date().getSeconds()
        }, 3000);
    },
    computed: {
        ...mapGetters (['dataZuluFormatada','horaZuluFormatada']),
        classMetar () {

            return this.class ? 'fw-bolder p-4 text-danger'
                            :'fw-bolder p-4'
        }
    },

    //esse observador faz o teste se o metar que está na rede é o correto
    //cada vez que a variável seg muda de valor
    watch: {
        seg() {
            if((this.dataZuluFormatada.substring(8,10) >= this.horaIni
             && this.dataZuluFormatada.substring(8,10) <= this.horaFim
              && this.horaIni < this.horaFim) || (this.horaIni > this.horaFim
               && this.dataZuluFormatada.substring(8,10) >= this.horaIni 
              && this.dataZuluFormatada.substring(8,10) <= 23) || 
              (this.horaIni > this.horaFim && 
              this.dataZuluFormatada.substring(8,10)  <= this.horaFim)){

                  if(parseInt(this.horaDoMetar) != parseInt(this.dataZuluFormatada.substring(8,10))
                   && (parseInt(this.horaZuluFormatada.substring(3,5)) >= 0 && parseInt(this.horaZuluFormatada.substring(3,5))<= 15) && this.localidade) {
                      
                       this.audio = true
                      this.play()
                      this.class = true
                  }else{
                      this.audio = false
                      this.class = false
                  }
              }else{
                  this.audio = false
                      this.class = false
              }
        }
    },
    //esse método faz a solicitação do metar com o clique de um botão
    methods: {
        solicitarLocalidade () {
            setInterval(() => {
                        axios.get(apiUrl.apiMetarAlerta.apiUrlMetarAlerta(this.localidade, this.dataZuluFormatada))
                            .then((response) => {

                                    this.metar = response.data.split('- ')[response.data.split('- ').length - 1]
                                
                                
                                if (this.metar.includes(' COR ') == false){
                                    this.horaDoMetar = this.metar.split(' ')[2].substring(2,4)
                                }else{
                                    this.horaDoMetar = this.metar.split(' ')[3].substring(2,4)
                                }
                                
                                this.busca = true
                            })
                            .catch((error) => {
                                if(error.request){
                                        this.mensagemErro = 'Erro ao tentar comunicar com o servidor!! '+ error
                                }
                                else if(error.response){
                                        this.mensagemErro = 'O servidor retornou um erro como resposta!! '+ error
                                    }
                                else {
                                    this.mensagemErro = 'Erro ao fazer requisição ao servidor!! '+ error
                                    }
                            })
                    }, 1000)

                    this.localidade = this.localSolicitacao 
                    this.busca = false
        },
        //esse método executa o beep
        play() {
        var x = document.getElementById("myAudio"); 

            x.play();
                             
        },
        //botão para fechar o alerta de erro de requisição
        fecharAlerta () {
            this.mensagemErro = undefined
        }
    }
    
}

</script>

<style scoped>

    .corText {
        color: #0D3B81
    }

    @media only screen and (max-width: 540px) {
        #rodape {
            display: none;
        }
    }

    .direitos{
        color: #ffffff;
        font-size: 15px;
        text-decoration: none;
    }
    
</style>
